<template>
    <v-navigation-drawer location="bottom" temporary v-model="options.isVisible" :style="{ height: drawerHeight + 'px' }">
        <v-list>
            <v-list-item :class="item.class" :key="item.name" @click.prevent="actionClickMenu(item)" link v-for="item in options.items">
                <v-list-item-title>
                    <v-icon v-if="item.icon" color="#757575" class="mb-1 mr-2">{{ item.icon }}</v-icon>
                    <v-badge :color="item.color" inline location="left" v-if="item.color"></v-badge>
                    {{ item.name }}
                </v-list-item-title>
            </v-list-item>
        </v-list>

        <template v-slot:prepend>
            <v-btn block class="drag-handle" color="white" variant="plain" tile @mousedown="startDrag" @touchstart="startDrag">
                <div class="drag-handle-ui"></div>
            </v-btn>
        </template>
    </v-navigation-drawer>
</template>

<script setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['hideMobileDrawer']);
const props = defineProps({
    options: Object,
});

const initialDrawerHeight = ref(((4 * 56) + 70));
const closeDrawerTreshold = ref(initialDrawerHeight.value * 0.7);
const drawerHeight = ref(initialDrawerHeight.value);

let startY = 0;
let startHeight = 0;

watch(() => props.options.isVisible, () => {
    // Restore full height after drag hide
    drawerHeight.value = initialDrawerHeight.value;
})

const doDrag = (event) => {
    // Calculate the new height based on the drag distance
    const clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;
    const newHeight = startHeight + startY - clientY;
    if (newHeight > 100) { // Set a minimum height
        drawerHeight.value = Math.round(newHeight);
    }
};

const stopDrag = () => {
    document.documentElement.removeEventListener('mousemove', doDrag, false);
    document.documentElement.removeEventListener('mouseup', stopDrag, false);
    document.documentElement.removeEventListener('touchmove', doDrag, false);
    document.documentElement.removeEventListener('touchend', stopDrag, false);

    if (props.options.isVisible) {
        if (drawerHeight.value < closeDrawerTreshold.value) {
            // Close drawer after major shrinking
            props.options.isVisible = false
        } else {
            // Restore full height after enlargment or minor shrinking
            drawerHeight.value = initialDrawerHeight.value
        }
    }
};

const startDrag = (event) => {
    event.preventDefault();
    startY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;
    startHeight = drawerHeight.value;

    document.documentElement.addEventListener('mousemove', doDrag, false);
    document.documentElement.addEventListener('mouseup', stopDrag, false);
    document.documentElement.addEventListener('touchmove', doDrag, false);
    document.documentElement.addEventListener('touchend', stopDrag, false);
};

const actionClickMenu = (item) => {
    emit('hideMobileDrawer')
    item.method(item.id)
}
</script>

<style scoped lang="scss">
.v-navigation-drawer {
    margin-bottom: 56px;
    border-bottom: 1px solid #ecedef;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform, visibility, height;

    .v-list-item {
        padding: 0 32px;
    }

    .v-list-item-title>.v-badge {
        margin: 6px 0 8px;
    }

    .drag-handle:active {
        cursor: ns-resize;
    }

    .drag-handle-ui {
        width: 81px;
        height: 4px;
        background: #a1a6b0;
        border-radius: 150px;
    }
}
</style>
