<template>
    <div>
        <v-navigation-drawer floating location="left" :temporary="isMobile" mobile-breakpoint="sm" class="sidebar-left">
            <div class="logo">
                <nuxt-link :to="'/projects/' + activeProjectId" draggable="false">
                    <img class="logo-img" src="/static/images/nodeup-logo.svg" />
                </nuxt-link>
            </div>

            <div :class="isProjectsOpen ? 'sidebar-menu sidebar-menu-open' : 'sidebar-menu'">
                <div @click="isProjectsOpen = !isProjectsOpen" class="sidebar-menu-title">PROJECTS</div>
                <v-list class="sidebar-menu-list" dense>
                    <v-list-item :key="'project_' + item.id" v-for="item in getProjects(activeTeamId)">
                        <nuxt-link :to="'/projects/' + item.id" draggable="false" :class="{ 'router-link-active': item.id == activeProjectId }">
                            <v-badge :color="item.color" inline left></v-badge>
                            {{ item.name }}
                        </nuxt-link>
                    </v-list-item>
                    <v-list-item>
                        <div>
                            <a @click="actionAddProject" draggable="false" class="cursor-pointer">
                                <v-icon class="plusicon">$plus</v-icon>New project
                            </a>
                        </div>
                    </v-list-item>
                </v-list>
            </div>

            <v-divider class="my-5" v-if="activeProjectId != 0"></v-divider>

            <div :class="isVirtualServerOpen ? 'sidebar-menu sidebar-menu-open' : 'sidebar-menu'" v-if="activeProjectId != 0">
                <div @click="isVirtualServerOpen = !isVirtualServerOpen" class="sidebar-menu-title">SERVICES</div>
                <v-list class="sidebar-menu-list" dense>
                    <v-list-item :key="'services_' + item.id" v-for="item in menuServices">
                        <nuxt-link :to="item.to" draggable="false">{{ item.name }}</nuxt-link>
                    </v-list-item>
                </v-list>
            </div>

            <v-divider class="my-5"></v-divider>

            <div :class="isTeamOpen ? 'sidebar-menu sidebar-menu-open' : 'sidebar-menu'">
                <div @click="isTeamOpen = !isTeamOpen" class="sidebar-menu-title">{{ isPersonalTeam() ? 'MY ACCOUNT' : 'TEAM' }}</div>
                <v-list class="sidebar-menu-list" dense>
                    <v-list-item v-if="$isAdmin()">
                        <nuxt-link to="/billing" draggable="false">Billing</nuxt-link>
                    </v-list-item>
                    <v-list-item v-if="$isAdmin()">
                        <nuxt-link to="/settings/team" draggable="false" v-if="!isPersonalTeam()">Team Settings</nuxt-link>
                        <nuxt-link to="/account" draggable="false" v-if="isPersonalTeam()">My Settings</nuxt-link>
                    </v-list-item>
                </v-list>
            </div>

            <v-divider class="my-5"></v-divider>

            <div class="sidebar-menu sidebar-menu-open">
                <v-list class="sidebar-menu-list" dense>

                    <v-list-item>
                        <a href="https://nodeup.io/support" draggable="false" target="_blank">Support</a>
                    </v-list-item>
                    <v-list-item v-if="showChat">
                        <a class="btn-launch-intercom-chat" draggable="false" href="#">Chat</a>
                    </v-list-item>
                </v-list>
            </div>
        </v-navigation-drawer>

        <formProjectAddEdit ref="refFormProjectAddEdit"></formProjectAddEdit>
    </div>
</template>

<script>
import { useNuxtApp } from '#app';
import { useStore } from '@/store/index';
import formProjectAddEdit from '~/components/forms/projectAddEdit'
import Project from '@/data/models/Project'
import Team from '@/data/models/Team'

export default {
    props: ['data'],
    components: {
        formProjectAddEdit
    },
    data() {
        return {
            menuServices: [
                { id: 1, name: 'Domains', to: '/domains' },
                { id: 2, name: 'Web Hosting', to: '/virtualservers' },
                { id: 3, name: 'Virtual Machines', to: '/vms' },
                { id: 4, name: 'VPN', to: '/vpns' },
            ],
            menuCloud: [
                { id: 5, name: 'Apps', to: '/apps' },
            ],
            isProjectsOpen: true,
            isVirtualServerOpen: true,
            isCloudOpen: true,
            isTeamOpen: true,
        }
    },
    computed: {
        activeProjectId() {
            const store = useStore();
            return store.project;
        },
        activeTeamId() {
            const store = useStore();
            return store.team;
        },
        isMobile() {
            const nuxtApp = useNuxtApp();
            return nuxtApp.$isMobile();
        }
    },
    methods: {
        isPersonalTeam() {
            const store = useStore();
            return Team.isPersonalTeam({ id: store.team });
        },
        getProjects(teamId) {
            return Project.getItems({
                where: ['teamId', parseInt(teamId)],
                orderBy: 'name'
            })
        },
        showChat() {
            const nuxtApp = useNuxtApp();
            return nuxtApp.$config.public.enableIntercom === 'true';
        },
        actionAddProject() {
            this.$refs.refFormProjectAddEdit.actionShow()
        },
    }
}
</script>

<style scoped lang="scss">
.sidebar-left {
    padding: 32px;
    width: 255px !important;
    overflow: visible;
    background-color: #ecedef !important;
    transition: all 0.4s;
    box-shadow: 0px 3px 6px rgba(227, 230, 233, 0.646173) !important;

    .logo {
        margin-bottom: 60px;
        margin-top: 30px;

        .logo-img {
            height: 28px;
        }
    }


    :deep(.v-navigation-drawer__content) {
        overflow: visible !important;
    }

    .v-list {
        overflow: visible !important;
        margin-right: -20px;

        .v-list-item {
            min-height: 34px;

            :deep(.v-list-item__content) {
                margin-right: -28px;
                margin-left: -48px;
            }
        }
    }


    .sidebar-menu {
        .sidebar-menu-title {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 1px;
            position: relative;
            cursor: pointer;
            color: #1c50c8;
            width: 209px;

            &:after {
                content: "";
                position: absolute;
                display: inline-block;
                height: 7px;
                width: 7px;
                right: 20px;
                top: 4px;
                vertical-align: top;
                border-style: solid;
                border-width: 2px 2px 0 0;
                border-color: #777;
                transform: rotate(135deg);
                transition: all 0.2s 0.5s;
            }
        }

        .sidebar-menu-list {
            display: block;
            padding-bottom: 0;

            .v-list-item--density-default.v-list-item--one-line {
                padding-top: 0;
                padding-bottom: 0;
            }

            a {
                text-decoration: none;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 16px;
                line-height: 32px;
                color: #000;
                padding-left: 32px;
                height: 32px;

                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(23px, max-content));
            }

            a.router-link-active {
                background-color: #d7d7d7;
                font-weight: 600;
            }

            :deep(.plusicon) {
                margin-right: 4px;
                margin-left: -5px;
                margin-top: 4px;

                svg {
                    width: 10px;
                    height: 10px;
                }

                path {
                    fill: #000;
                }
            }

            .v-badge--inline {
                margin-left: -5px;
                margin-top: 5px;
            }
        }
    }

    .v-divider {
        border-color: #b8b8b9 !important;
    }

    .sidebar-menu-open .sidebar-menu-title {
        &:after {
            transform: rotate(-45deg);
            transition: all 0.2s 0.5s;
            margin-top: 3px;
        }
    }


    /* transition effects */
    .sidebar-menu-list {
        max-height: 0;
        padding: 0;
        opacity: 0;
    }

    .sidebar-menu-open .sidebar-menu-list {
        max-height: 2400px;
        padding: 8px 0;
        opacity: 1;
        transition: max-height 0.5s, opacity 0.5s 0.2s;
    }
}
</style>
